html,
body,
#root {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

table tr:has(+ .Summary) td {
  border-bottom: none !important;
}
table .Summary td {
  border-top: 1px solid #666;
}

.DottedLink {
  border-bottom: 1px dashed #000;
  cursor: pointer;
}

.DottedLink:hover {
  border-bottom: 1px solid #0f9648;
}

.taskComments .ant-list-items {
  display: flex;
  flex-direction: column-reverse;
}
.ant-table-content {
  max-height: 400px; /* Adjust as needed */
  overflow-y: auto;
}

.ant-table-content table {
  width: 100%;
  border-collapse: collapse;
}

.ant-table-content thead th {
  position: sticky;
  top: 0;
  background: #f2f2f2;
  z-index: 1;
}